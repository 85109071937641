import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../Context/AuthContext";

import Ian from "../../assets/images/Ian.png";
import Kate from "../../assets/images/Kate_new.png";
import Leonardo from "../../assets/images/Leonardo.jpg";
import Lisa from "../../assets/images/Lisa_new.png";
import Megan from "../../assets/images/Megan.png";
import Bin from "../../assets/images/Bin.png";
import { listAffinityGroups } from "../../api/affinityGroups";


const AboutUs = ({ isLoggedIn, isAdvisor, userId }) => {
  const [affinityGroups, setAffinityGroups] = useState([]);
  const redirectToUrl = isLoggedIn ? "/search" : "/login";
  const mainButtonText = isLoggedIn ? "Find an Advisor" : "Sign In";
  useEffect(() => {
    listAffinityGroups().then((response) => {
      setAffinityGroups(response.data);
    });
  }, []);

  const affinityGroupsSections = [
    {
      sectionName: "Endowments / Foundations / Trusts / Institutional",
      participants: [],
    },
    {
      sectionName: "HNW Individual Impact Investors & Families",
      participants: [],
    },
    {
      sectionName: "Philanthropically Focused",
      participants: [],
    },
    {
      sectionName: "Entrepreneurs",
      participants: [],
    },
    {
      sectionName: "Consultants & Intermediaries",
      participants: [],
    },
  ].map((section) => {
    const participants = affinityGroups
      .filter((ag) => ag.category === section.sectionName)
      .sort((a,b) => {
        const createdAtA = new Date(a.createdAt)
        const createdAtB = new Date(b.createdAt)
        return createdAtA - createdAtB
      })
      .map((ag) => {
        return {
          url: ag.website,
          img: ag.blurb,
          text: (
            <div
              className="partners__text"
              dangerouslySetInnerHTML={{ __html: ag.description }}
            ></div>
          ),
        };
      })

    return {
      sectionName: section.sectionName,
      participants: participants,
    };
  });

  return (
    <main>
      <div className="helpintro">
        <div className="uk-container">
          <div className="uk-grid">
            <div className="uk-width-1-1">
              <h3 className="helpintro__text">
                ValuesAdvisor can be <span>the</span> essential first step,{" "}
                <br /> to investing with impact.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="partners">
        <div className="uk-container partners__container">
          <div className="uk-width-1-1">
            <h3 className="partners__title">Our Affinity Groups</h3>
            <p className="partners__subtitle">
              ValuesAdvisor works with networks and organizations who, like us,
              support individuals and institutions that want to align their
              money with their values by sharing the platform with their
              members. All financial advisors on the platform have been
              recommended by members of our affinity groups.
              <br />
              <br />
              Explore the organizations below to discover a community you might
              like to join. Click on the logos to visit their homepages and
              learn more.
            </p>
          </div>
          <div uk-grid={"true"} className="uk-grid uk-flex uk-flex-between">
            <div className="uk-width-1-2@l uk-width-1-1">
              <div className="uk-margin-medium-bottom  uk-grid uk-flex uk-flex-middle">
                <div className="uk-width-1-1">
                  <p className="partner__honorary">FISCAL SPONSOR</p>
                  <div
                    className="uk-flex uk-flex-between partners__logo-wrapper"
                    uk-grid={"true"}
                  >
                    <a
                      className="uk-flex-1-1 uk-flex-1-2@s uk-margin-auto"
                      href="https://rsfsocialfinance.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        id="rsf-img"
                        className="partners__logo main__partner"
                        src="img/logo-rsf.svg"
                        alt=""
                      />
                    </a>
                    <div className="uk-flex-1-1 uk-width-1-1@l uk-width-1-2@s partners__text-wrapper">
                      <p className="partners__text">
                        RSF Social Finance is a non-profit financial services
                        organization that creates financial relationships that
                        are direct, transparent, personal and focused on
                        long-term social, economic and ecological benefit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@l uk-width-1-1">
              <div className="uk-margin-medium-bottom uk-grid uk-flex uk-flex-middle">
                <div className="uk-width-1-1">
                  <p className="partner__honorary">FOUNDING PARTNER</p>
                  <div
                    className="uk-flex uk-flex-between partners__logo-wrapper"
                    uk-grid={"true"}
                  >
                    <a
                      className="uk-flex-1-1 uk-flex-1-2@s uk-margin-auto"
                      href="https://www.thresholdfoundation.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        id="threshold-foundation-img"
                        className="partners__logo main__partner"
                        src="img/threshold-foundation.png"
                        alt=""
                      />
                    </a>
                    <div className="uk-flex-1-1 uk-width-1-1@l uk-width-1-2@s partners__text-wrapper">
                      <p className="partners__text">
                        Threshold is a community of individuals united through
                        wealth, and a progressive foundation mobilizing money,
                        people and power to create a more just, joyful and
                        sustainable world.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          {affinityGroupsSections.map((section) => {
              return (
                <div id="affinity-group-participants" key={section.sectionName}>
                  <p className="partner__honorary">
                    {section.sectionName.toUpperCase()}
                  </p>
                  <div
                    uk-grid={"true"}
                    className="uk-grid uk-flex uk-grid-stack uk-flex-left afinity-grid"
                  >
                    {section.participants.map((participant) => {
                      return (
                        <div
                          className="uk-width-1-3@l uk-width-1-2@s uk-width-1-1"
                          key={participant.url}
                        >
                          <div className="uk-grid uk-flex uk-flex-middle uk-margin-medium-bottom">
                            <div className="uk-width-1-1@l">
                              <a
                                href={participant.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="partners__logo"
                                  src={participant.img}
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="uk-width-1-1@l">
                              {participant.text}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div
        className="uk-container gray_background our_financial_advisors_div"
        id="our-financial-advisors"
      >
        <p className="learn-more-div-section-title our_financial_advisors_div-title">
          Our Financial Advisors
        </p>
        <p>
          Advisors on the ValuesAdvisor platform are suggested by experienced
          impact investors and meet this criteria:{" "}
        </p>
        <br />
        <ul className="list-with-orange-arrows">
          <li>
            Be recommended by at least one member of a ValuesAdvisor Partner
            Affinity Group or an expert in the impact investing field.
          </li>
          <li>
            Offer impact-based financial advisory services as a core business
            (25% or more of current AUM and highlighted on their website).
          </li>
          <li>
            Demonstrate significant knowledge of the impact investing field,
            offering values aligned investment options for a minimum of three
            years and/or expertise.
          </li>
        </ul>
        <br />
        <p>
          ValuesAdvisor brings transparency and consistency to key information
          about the Financial Advisors and firms on the platform, so each
          investor can make the choice that meets their needs.
        </p>
      </div>

      <div className="ourteam" id="our-team">
        <div className="uk-container">
          <div className="uk-width-1-1">
            <h3 className="ourteam__title">Our Team</h3>
          </div>
          <div className="uk-grid team-grid">
            <div className="uk-width-1-3@m">
              <div className="member">
                <div className="uk-grid uk-flex-middle@m">
                  <div className="uk-width-1-1@m uk-width-1-3@s">
                    <img className="ourteam__pic" src={Lisa} alt="" />
                  </div>
                  <div className="uk-width-1-1@m uk-width-2-3@s">
                    <h3 className="ourteam__name">
                      Lisa Renstrom{" "}
                      <a
                        className="uk-icon-button uk-icon"
                        href="https://www.linkedin.com/in/lisa-renstrom-31665b3"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          data-svg="linkedin"
                        >
                          <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path>
                          <path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path>
                        </svg>
                      </a>
                    </h3>
                    <p className="ourteam__text">
                      ValuesAdvisor is a solution to a problem I encountered in
                      the DivestInvest Individual Campaign. People committed to
                      divest, but their advisors didn’t know how to invest
                      FossilFree. The excel spreadsheet of value-aligned
                      advisors I shared became a robust platform with all the
                      information my peers sought, organized in a simple
                      interface. ValuesAdvisor.org is my contribution to
                      building beautiful portfolios and a better world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-3@m">
              <div className="member">
                <div className="uk-grid uk-flex-middle uk-flex">
                  <div className="uk-width-1-1@m uk-width-1-3@s">
                    <img className="ourteam__pic" src={Megan} alt="" />
                  </div>
                  <div className="uk-width-1-1@m uk-width-2-3@s">
                    <h3 className="ourteam__name">
                      Megan Morrice{" "}
                      <a
                        className="uk-icon-button uk-icon"
                        href="https://www.linkedin.com/in/meganmorrice"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          data-svg="linkedin"
                        >
                          <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path>
                          <path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path>
                        </svg>
                      </a>
                    </h3>
                    <p className="ourteam__text">
                      After a decade in the traditional financial services
                      industry, I wanted to align my career with my values. I
                      earned an MBA with a focus on sustainability, and pivoted
                      my career into advancing impact investing. My experience
                      on both the traditional and the impact side of the
                      financial services industry gives me a unique
                      understanding of the services financial advisors can
                      provide and options clients have for investing their
                      money.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-3@m">
              <div className="member">
                <div className="uk-grid uk-flex-middle uk-flex">
                  <div className="uk-width-1-1@m uk-width-1-3@s">
                    <img className="ourteam__pic" src={Kate} alt="" />
                  </div>
                  <div className="uk-width-1-1@m uk-width-2-3@s">
                    <h3 className="ourteam__name">
                      Kate Simpson{" "}
                      <a
                        className="uk-icon-button uk-icon"
                        href="https://www.linkedin.com/in/katesimpson3"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          data-svg="linkedin"
                        >
                          <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path>
                          <path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path>
                        </svg>
                      </a>
                    </h3>
                    <p className="ourteam__text">
                      Throughout my career, I’ve been interested in helping
                      investors use the power of their investment capital to
                      positively influence social and environmental issues. I
                      developed and co-authored “Take Two: A Journal for New
                      Beginnings” published by Chronicle Books and wrote
                      “Finding Your Way to the Right Impact Investment Advisor:
                      A Resource Guide” for Confluence Philanthropy. At
                      ValuesAdvisor I bring my understanding of the power of
                      resilience to investors and investing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-3@m">
              <div className="member">
                <div className="uk-grid uk-flex-middle">
                  <div className="uk-width-1-1@m uk-width-1-3@s">
                    <img className="ourteam__pic" src={Ian} alt="" />
                  </div>
                  <div className="uk-width-1-1@m uk-width-2-3@s">
                    <h3 className="ourteam__name">Ian Brownell </h3>
                    <p className="ourteam__text">
                      I got involved with ValuesAdvisor as a potential user. I
                      inherited my financial advisors at birth along with my
                      wealth and only recently started to take some
                      responsibility and control over how my money is invested.
                      Learning about Impact Investing and meeting impact experts
                      has helped educate my current advisors and brought them
                      into closer alignment with my values.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 dev-team-grid">
            <h3 className="ourteam__developer">DEVELOPMENT TEAM</h3>
          </div>
          <div className="uk-grid">
            <div className="uk-width-1-3@m">
              <div className="member">
                <div className="uk-grid uk-flex-middle">
                  <div className="uk-width-1-1@m uk-width-1-3@s">
                    <img className="ourteam__pic" src={Bin} alt="" />
                  </div>
                  <div className="uk-width-1-1@m uk-width-2-3@s">
                    <h3 className="ourteam__name">
                      Bin Huang{" "}
                      <a
                        className="uk-icon-button uk-icon"
                        href="https://www.linkedin.com/in/bin-huang-5a615723/"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          data-svg="linkedin"
                        >
                          <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path>
                          <path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path>
                        </svg>
                      </a>
                    </h3>
                    <p className="ourteam__text">
                      I’m always interested in how design can make a positive
                      impact on the world directly and indirectly. ValuesAdvisor
                      is a perfect example of a project where thoughtful design
                      of information communication can lead to more meaningful
                      interactions between investors and advisors. I’m honored
                      to be leading the brand and user experience design for
                      ValuesAdvisor and looking forward to helping to grow the
                      emerging impact investors community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-3@m">
              <div className="member">
                <div className="uk-grid uk-flex-middle">
                  <div className="uk-width-1-1@m uk-width-1-3@s">
                    <img className="ourteam__pic" src={Leonardo} alt="" />
                  </div>
                  <div className="uk-width-1-1@m uk-width-2-3@s">
                    <h3 className="ourteam__name">
                      Leonardo Jimenez{" "}
                      {/* <a className="uk-icon-button uk-icon" href="#" uk-icon="icon: linkedin" /> */}
                    </h3>
                    <p className="ourteam__text">
                      Leonardo Jimenez is an Industrial Engineer with a deep
                      expertise in digital transformation. Using the power of
                      software to improve and revolutionize how Financial
                      companies operate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-link">
        <div className="uk-container">
          <div className="uk-grid">
            <div className="uk-width-1-1 uk-flex uk-flex-center uk-flex-column">
              {!isLoggedIn ? (
                <p className="uk-text-center ready-to-get-started">
                  Ready to Get Started?
                </p>
              ) : null}
              <div className="uk-width-1-1 uk-flex uk-flex-center">
                <a
                  href={`${redirectToUrl}`}
                  className="homelink homelink-size-fix"
                >
                  {`${mainButtonText}`}
                  <svg
                    className="homelink__chevron"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="19.521"
                    viewBox="0 0 11 19.521"
                  >
                    <g id="Expand" transform="translate(0 19.521) rotate(-90)">
                      <path
                        id="Path_434"
                        data-name="Path 434"
                        d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                        transform="translate(-178.807 -14.7)"
                        fill="#ff6b00"
                      />
                    </g>
                  </svg>
                </a>
                {isLoggedIn && isAdvisor ? (
                  <a
                    href={`/profile/${userId}`}
                    className="homelink homelink--transparent homelink-size-fix"
                  >
                    My Profile
                    <svg
                      className="homelink__chevron"
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="19.521"
                      viewBox="0 0 11 19.521"
                    >
                      <g
                        id="Expand"
                        transform="translate(0 19.521) rotate(-90)"
                      >
                        <path
                          id="Path_434"
                          data-name="Path 434"
                          d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                          transform="translate(-178.807 -14.7)"
                          fill="#ff6b00"
                        />
                      </g>
                    </svg>
                  </a>
                ) : null}
                {!isLoggedIn ? (
                  <a
                    href="/join"
                    className="homelink homelink--transparent homelink-size-fix"
                  >
                    Subscribe{" "}
                    <svg
                      className="homelink__chevron"
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="19.521"
                      viewBox="0 0 11 19.521"
                    >
                      <g
                        id="Expand"
                        transform="translate(0 19.521) rotate(-90)"
                      >
                        <path
                          id="Path_434"
                          data-name="Path 434"
                          d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                          transform="translate(-178.807 -14.7)"
                          fill="#ff6b00"
                        />
                      </g>
                    </svg>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
const Container = () => {
  const authContext = useContext(AuthContext);
  const { currentUserID, isAdvisor, isLoggedIn } = authContext;

  return (
    <AboutUs
      isLoggedIn={isLoggedIn}
      isAdvisor={isAdvisor}
      userId={currentUserID}
    ></AboutUs>
  );
};
export default Container;
