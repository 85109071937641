import React, { useEffect, useState } from "react";
import { SimilarAdvisors } from "./SimilarAdvisors/SimilarAdvisors";
import { DownloadLinks } from "./DownloadLinks/DownloadLinks";
import { SocialMediaLinks } from "./SocialMediaLinks/SocialMediaLinks";
import { OtherOffices } from "./OtherOffices/OtherOffices";
import { PrintProfile } from "./PrintProfile/PrintProfile";
import { HeadQuarters } from "./HeadQuarters/HeadQuarters";
import { Tabs } from "./Tabs/Tabs";
import { Introduction } from "./Introduction/Introduction";
import { capitalize } from "lodash";
import PropTypes from "prop-types";
import bodymovin from "lottie-web";
import handAnimation from "../../assets/Lotties/hand_animation.json";

import { Address } from "../../models/address";
import {
  Advisor,
  Firm,
  TeamInfo,
  ValuesAlignedInvestingInfo,
} from "../../models/advisor";

function setHiBtnPosition() {
  document.addEventListener("scroll", function (e) {
    const sayHiBtnContainer = document.getElementById("say-hi-btn-container");
    const sayHiButtonLimitElement = document.getElementById("say-hi-btn-limit");
    if (sayHiButtonLimitElement) {
      const limitRect = document;
      sayHiButtonLimitElement.getBoundingClientRect();

      if (window.innerHeight - limitRect.bottom >= 1) {
        sayHiBtnContainer.style.bottom = "unset";
        sayHiBtnContainer.style.top =
          limitRect.top - sayHiBtnContainer.offsetHeight + "px";
      } else {
        sayHiBtnContainer.style.top = "unset";
        sayHiBtnContainer.style.bottom = "0";
      }
    }
  });
}

const AdvisorProfile = ({
  firm = {},
  teamInfo = {},
  introductionProps = {},
  addresses = [],
  website = "",
  socialLinkTypes = [],
  downloadLinksProps,
  unsdgTypes = [],
  tabProps = {},
  similarAdvisors = [],

  onPrintProfile = () => {},
  onGetInTouch = (e) => {},
  onEditProfile = () => {},
  onApproveProfile = () => {},
  onRejectProfile = () => {},
  showSimilarAdvisors = true,
  onSendHiInfo = async () => {},
  setSayHiName = async () => {},
  setSayHiEmail = async () => {},
  setSayHiMessage = async () => {},
}) => {
  const [isSendHiMessageDisabled, setIsSendHiMessageDisabled] = useState(true);
  useEffect(() => {
    setHiBtnPosition();
  }, []);
  if (!teamInfo || !Object.keys(teamInfo).length) {
    return null;
  }
  if (!firm || !Object.keys(firm).length) {
    return null;
  }
  if (!introductionProps || !Object.keys(introductionProps).length) {
    return null;
  }
  if (!tabProps || !Object.keys(tabProps).length) {
    return null;
  }

  const sendHiInfo = async (e) => {
    e.preventDefault();
    //samy stuff here
    await onSendHiInfo();
    //louve stuff here
    let saiHiDivs = document.getElementsByClassName("say-hi-form-container");
    for (let i = 0; i < saiHiDivs.length; i++) {
      saiHiDivs[i].classList.add("thank-you");
    }
  };

  /* Shapes */
  const svgContainers = document.getElementsByClassName(
    "inner-lottie-container"
  );

  for (let item of svgContainers) {
    setTimeout(function () {
      let animItem = bodymovin.loadAnimation({
        wrapper: item,
        animType: "svg",
        loop: false,
        // autoplay: false,
        // pause: true,
        animationData: handAnimation,
      });
      animItem.setSpeed(0.55);

      let wigglingHands = document.getElementsByClassName("wiggle");
      for (var i = 0; i < wigglingHands.length; i++) {
        wigglingHands.item(i).classList.add("wiggling");
      }

      animItem.addEventListener("complete", function () {
        animItem.hide();
        for (var i = 0; i < wigglingHands.length; i++) {
          wigglingHands.item(i).classList.remove("wiggling");
        }
        setTimeout(function () {
          animItem.goToAndPlay(0);
          animItem.show();
          for (var i = 0; i < wigglingHands.length; i++) {
            wigglingHands.item(i).classList.add("wiggling");
          }
        }, 5000);
      });
    }, 5000);
  }

  return (
    <main data-testid={"advisor-profile-container"}>
      <Introduction
        className="no-print-show"
        {...introductionProps}
        firm={firm}
        teamInfo={teamInfo}
        unsdgTypes={unsdgTypes}
        {...downloadLinksProps}
        socialLinkTypes={socialLinkTypes}
        addresses={addresses}
        website={website}
        onPrintProfile={onPrintProfile}
        onGetInTouch={onGetInTouch}
        onEditProfile={onEditProfile}
        onApproveProfile={onApproveProfile}
        onRejectProfile={onRejectProfile}
      />
      {/* maincomponents start here */}
      <div className="maincomponent advisor-profile__maincomponent --no-margin-top no-print-show">
        <div className="uk-container">
          <div className="uk-grid uk-grid-divider fix-border">
            <div className="uk-width-1-4 uk-first-column uk-visible@m">
              <div className="filter-midle advisor-profile-filter">
                <div className="filter-section">
                  <div className="uk-grid">
                    <div className="uk-width-1-1">
                      <div uk-accordion={"true"} className="uk-accordion">
                        <div className="uk-open headquarters-nested-section">
                          <HeadQuarters
                            addresses={addresses}
                            website={website}
                          />

                          <SocialMediaLinks socialLinkTypes={socialLinkTypes} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="filter-section">
                  <div className="uk-grid">
                    <div className="uk-width-1-1">
                      <OtherOffices addresses={addresses} />
                    </div>
                  </div>
                </div>

                <div className="filter-section">
                  <DownloadLinks {...downloadLinksProps} />
                </div>
                {unsdgTypes.length ? (
                  <React.Fragment>
                    {/*<hr />*/}
                    <div className="filter-section">
                      <div className="uk-grid">
                        <div className="uk-width-1-1 impact-section">
                          <h3 className="section-title">
                            Impact Expertise in UN Sustainable Development Goals
                          </h3>
                        </div>
                      </div>
                      <div className="report">
                        <div className="uk-grid">
                          {unsdgTypes.map((u) => {
                            return (
                              <div key={u.id} className="uk-width-1-2">
                                <div className="report__item">
                                  <div>
                                    <img src={`img/icons/${u.icon}`} alt="" />
                                  </div>
                                  <p>
                                    {u.value
                                      .split(" ")
                                      .map((s) => capitalize(s))
                                      .join(" ")}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
            <Tabs {...tabProps} teamInfo={teamInfo} firm={firm} />
          </div>
        </div>
      </div>
      <div id="say-hi-btn-container">
        <div className="lottie-container mobile-only">
          {/*<Lottie*/}
          {/*    options={lottieDefaultOptions}*/}
          {/*    height={150}*/}
          {/*    width={150}*/}
          {/*/>*/}
          {/*/>*/}
          <div className="inner-lottie-container"></div>
          <button
            id="drop-a-note-mobile"
            className="say-hi-btn uk-hidden@s"
            href="#my-id"
            type="button"
            uk-toggle="target: #mobile-say-hi-form-wrapper"
          >
            <img className="wiggle" src="img//icons/say-hi-icon.svg" alt="" />
            <p>Quick Ask</p>
          </button>
        </div>
        <div className="uk-visible@s medium-up-form-wrapper">
          <div className="lottie-container medium-up">
            {/*<Lottie*/}
            {/*    options={lottieDefaultOptions}*/}
            {/*    height={150}*/}
            {/*    width={150}*/}
            {/*/>*/}
            <button
              className="say-hi-btn"
              type="button"
              id="medium-up-say-hi-btn"
            >
              <img className="wiggle" src="img//icons/say-hi-icon.svg" alt="" />
              <p>Quick Ask</p>
            </button>
            <div
              className="medium-up-say-hi-form-container say-hi-form-container"
              uk-drop="mode: click; pos: top-right"
            >
              <div className="say-hi-header">
                <p>Say hi to {introductionProps.name}</p>
                <button
                  id="medium-up-say-hi-btn-clone"
                  className="uk-drop-close"
                  type="button"
                  uk-close={"true"}
                >
                  <img src="img/icons/say-hi-close.svg" alt="" />
                </button>
              </div>

              <form onSubmit={sendHiInfo} className="say-hi-form">
                <div className="say-hi-input-wrapper">
                  <label>Name</label>
                  <input
                    type="text"
                    required
                    name="name"
                    onChange={(e) => {
                      setSayHiName(e.target.value);
                    }}
                  />
                </div>
                <div className="say-hi-input-wrapper">
                  <label>Email</label>
                  <input
                    type="email"
                    required
                    name="email"
                    onChange={(e) => {
                      setSayHiEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="say-hi-form-radio-btns-wrapper">
                  <label className="radio-main-label">Message</label>
                  <div className="say-hi-radio-btn-container">
                    <input
                      type="radio"
                      name="question_message"
                      value="I have a question about one of your profile answers."
                      onChange={(e) => {
                        setSayHiMessage(e.target.value);
                        setIsSendHiMessageDisabled(true);
                      }}
                    />
                    <div className="input-dummy"></div>
                    <label htmlFor="html">
                      I have a question about one of your profile answers.
                    </label>
                  </div>
                  <div className="say-hi-radio-btn-container">
                    <input
                      type="radio"
                      name="question_message"
                      value="Can you tell me more about your impact reporting?"
                      onChange={(e) => {
                        setSayHiMessage(e.target.value);
                        setIsSendHiMessageDisabled(true);
                      }}
                    />
                    <div className="input-dummy"></div>
                    <label htmlFor="html">
                      Can you tell me more about your impact reporting?
                    </label>
                  </div>
                  <div className="say-hi-radio-btn-container">
                    <input
                      type="radio"
                      name="question_message"
                      value="I’d love some help with terminology."
                      onChange={(e) => {
                        setSayHiMessage(e.target.value);
                        setIsSendHiMessageDisabled(true);
                      }}
                    />
                    <div className="input-dummy"></div>
                    <label htmlFor="html">
                      I’d love some help with terminology.
                    </label>
                  </div>
                  <div className="say-hi-radio-btn-container">
                    <input
                      type="radio"
                      name="question_message"
                      value="Other message"
                      onChange={() => {
                        setIsSendHiMessageDisabled(false);
                      }}
                    />
                    <div className="input-dummy"></div>
                    <label htmlFor="html">Other message</label>
                  </div>
                </div>
                <textarea
                  className="say-hi-form-text-area"
                  placeholder="Type your message here."
                  name="question_text_area_message"
                  disabled={isSendHiMessageDisabled}
                  onChange={(e) => {
                    setSayHiMessage(e.target.value);
                  }}
                />
                <div className="send-hi-message-container">
                  <span>SEND</span>
                  <button type="submit">
                    <img src="img/icons/say-hi-send.svg" alt="" />
                  </button>
                </div>
              </form>
              <div className="thank-you-content">
                <div className="say-hi-header">
                  <p></p>
                  <button
                    id="medium-up-say-hi-btn-clone"
                    className="uk-drop-close"
                    type="button"
                    uk-close={"true"}
                  >
                    <img src="img/icons/thank-you-close.svg" alt="" />
                  </button>
                </div>
                <p className="thank-you-text">Thank You!</p>
                <div className="thank-you-separator"></div>
                <p className="in-touch-text">
                  We’ll be in touch <br /> shortly.
                </p>
                <div className="send-hi-message-container close-hi-message-container">
                  <span>CLOSE</span>
                  <button
                    className="uk-drop-close"
                    type="button"
                    uk-close={"true"}
                  >
                    <img src="img/icons/thank-you-content-close.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div className="inner-lottie-container"></div>
          </div>
        </div>
      </div>

      <div
        id="mobile-say-hi-form-wrapper"
        uk-offcanvas="overlay: true"
        className="uk-hidden@s"
      >
        <div className="uk-offcanvas-bar mobile-say-hi-form-container say-hi-form-container">
          <div className="say-hi-header">
            <p>Say hi to {introductionProps.name}</p>
            <button
              className="uk-offcanvas-close"
              type="button"
              uk-close={"true"}
            >
              <img src="img/icons/say-hi-close.svg" alt="" />
            </button>
          </div>

          <form onSubmit={sendHiInfo} className="say-hi-form">
            <div className="say-hi-input-wrapper">
              <label>Name</label>
              <input
                type="text"
                name="name"
                onChange={(e) => {
                  setSayHiName(e.target.value);
                }}
              />
            </div>
            <div className="say-hi-input-wrapper">
              <label>Email</label>
              <input
                type="email"
                name="email"
                onChange={(e) => {
                  setSayHiEmail(e.target.value);
                }}
              />
            </div>
            <div className="say-hi-form-radio-btns-wrapper">
              <label className="radio-main-label">Message</label>
              <div className="say-hi-radio-btn-container">
                <input
                  type="radio"
                  name="question_message"
                  value="I have a question about one of your profile answers."
                  onChange={(e) => {
                    setSayHiMessage(e.target.value);
                    setIsSendHiMessageDisabled(true);
                  }}
                />
                <div className="input-dummy"></div>
                <label htmlFor="html">
                  I have a question about one of your profile answers.
                </label>
              </div>
              <div className="say-hi-radio-btn-container">
                <input
                  type="radio"
                  name="question_message"
                  value="Can you tell me more about your impact reporting?"
                  onChange={(e) => {
                    setSayHiMessage(e.target.value);
                    setIsSendHiMessageDisabled(true);
                  }}
                />
                <div className="input-dummy"></div>
                <label htmlFor="html">
                  Can you tell me more about your impact reporting?
                </label>
              </div>
              <div className="say-hi-radio-btn-container">
                <input
                  type="radio"
                  name="question_message"
                  value="I’d love some help with terminology."
                  onChange={(e) => {
                    setSayHiMessage(e.target.value);
                    setIsSendHiMessageDisabled(true);
                  }}
                />
                <div className="input-dummy"></div>
                <label htmlFor="html">
                  I’d love some help with terminology.
                </label>
              </div>

              <div className="say-hi-radio-btn-container">
                <input
                  type="radio"
                  name="question_message"
                  value="Other message"
                  disabled={isSendHiMessageDisabled}
                  onChange={(e) => {
                    setSayHiMessage(e.target.value);
                  }}
                />
                <div className="input-dummy"></div>
                <label htmlFor="html">Other message</label>
              </div>
            </div>
            <textarea
              className="say-hi-form-text-area"
              placeholder="Type your message here."
              name="question_text_area_message"
            />
            <div className="send-hi-message-container">
              <span>SEND</span>
              <button type="submit">
                <img src="img/icons/say-hi-send.svg" alt="" />
              </button>
            </div>
          </form>
          <div className="thank-you-content">
            <div className="say-hi-header">
              <p></p>
              <button
                className="uk-offcanvas-close"
                type="button"
                uk-close={"true"}
              >
                <img src="img/icons/thank-you-close.svg" alt="" />
              </button>
            </div>
            <p className="thank-you-text">Thank You!</p>
            <div className="thank-you-separator"></div>
            <p className="in-touch-text">
              We’ll be in touch <br /> shortly.
            </p>
            <div className="send-hi-message-container close-hi-message-container">
              <span>CLOSE</span>
              <button
                className="uk-offcanvas-close"
                type="button"
                uk-close={"true"}
              >
                <img src="img/icons/thank-you-content-close.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="say-hi-btn-limit no-print-show"></div>
      <div className="drop-a-note no-print-show">
        <div className="uk-container">
          <div
            className="drop-a-note-flex uk-child-width-1-1 uk-flex uk-flex-center"
            uk-grid={"true"}
          >
            <div className="uk-child-width-1-2 uk-flex uk-flex-center uk-flex-left@m drop-a-note-wrapper">
              <div className="image-container image-contain-container drop-a-note-question-image-container">
                <img src="img/drop-a-note-1.png" alt="" />
              </div>

              <div className="uk-flex uk-flex-center uk-flex-column text-link-container uk-hidden@s">
                <p className="uk-text-center">
                  Have a question for <br />
                  {introductionProps.name}?
                </p>
                <button
                  id="drop-a-note-opener-mobile"
                  className="homelink uk-margin-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    if (window.UIkit) {
                      window.UIkit.toggle(
                        "#mobile-say-hi-form-wrapper"
                      ).toggle();
                    }
                  }}
                >
                  Drop a Note
                  <svg
                    className="homelink__chevron"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="19.521"
                    viewBox="0 0 11 19.521"
                  >
                    <g id="Expand" transform="translate(0 19.521) rotate(-90)">
                      <path
                        id="Path_434"
                        data-name="Path 434"
                        d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                        transform="translate(-178.807 -14.7)"
                        fill="#ff6b00"
                      />
                    </g>
                  </svg>
                </button>
              </div>

              <div className="uk-flex uk-flex-center uk-flex-column text-link-container uk-visible@s">
                <p className="uk-text-center">
                  Have a question for <br />
                  {introductionProps.name}?
                </p>
                <button
                  id="drop-a-note-opener-medium-up"
                  className="homelink uk-margin-auto"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();

                    if (window.UIkit) {
                      window.UIkit.drop(
                        ".medium-up-say-hi-form-container"
                      ).show();
                    }
                  }}
                  //uk-toggle="target: #mobile-say-hi-form-wrapper"
                >
                  Drop a Note
                  <svg
                    className="homelink__chevron"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="19.521"
                    viewBox="0 0 11 19.521"
                  >
                    <g id="Expand" transform="translate(0 19.521) rotate(-90)">
                      <path
                        id="Path_434"
                        data-name="Path 434"
                        d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                        transform="translate(-178.807 -14.7)"
                        fill="#ff6b00"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div className="uk-child-width-1-2 uk-flex uk-flex-center uk-flex-left@m uk-flex-row-reverse drop-a-note-wrapper drop-a-note-contact">
              <div className="image-container image-contain-container drop-a-note-contact-image-container">
                <img src="img/drop-a-note-3.png" alt="" />
              </div>

              <div className="uk-flex uk-flex-center uk-flex-column text-link-container">
                <p className="uk-text-center">
                  Have a question for <br />
                  ValuesAdvisor?
                </p>
                <a
                  href="/contact"
                  className="homelink uk-margin-auto drop-a-note-contact-btn"
                >
                  Contact Us
                  <svg
                    className="homelink__chevron"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="19.521"
                    viewBox="0 0 11 19.521"
                  >
                    <g id="Expand" transform="translate(0 19.521) rotate(-90)">
                      <path
                        id="Path_434"
                        data-name="Path 434"
                        d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                        transform="translate(-178.807 -14.7)"
                        fill="#ff6b00"
                      />
                    </g>
                  </svg>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* maincomponents ends here */}
      {/* similar profiles starts here */}
      {showSimilarAdvisors ? (
        <SimilarAdvisors
          className="no-print-show"
          similarAdvisors={similarAdvisors}
        />
      ) : null}
      {/* similar profiles ends here */}
      <PrintProfile
        {...introductionProps}
        firm={firm}
        teamInfo={teamInfo}
        unsdgTypes={unsdgTypes}
        {...downloadLinksProps}
        socialLinkTypes={socialLinkTypes}
        addresses={addresses}
        website={website}
        {...tabProps}
      />
    </main>
  );
};
AdvisorProfile.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.instanceOf(Address)),
  firm: PropTypes.instanceOf(Firm),
  teamInfo: PropTypes.instanceOf(TeamInfo),
  introductionProps: PropTypes.exact({
    impactThemesExpertise: PropTypes.array,
    name: PropTypes.string,
    profilePic: PropTypes.string,
    suggestedByMemberTypes: PropTypes.array,
    title: PropTypes.string,
    user: PropTypes.object,
  }),
  downloadLinksProps: PropTypes.exact({
    currentADV: PropTypes.string,
    materialTypes: PropTypes.array,
    richMediaLinks: PropTypes.array,
  }),
  tabProps: PropTypes.exact({
    valuesAlignedInvestingInfo: PropTypes.instanceOf(
      ValuesAlignedInvestingInfo
    ),
    types: PropTypes.object,
  }),
  unsdgTypes: PropTypes.array,
  website: PropTypes.string,
  socialLinkTypes: PropTypes.array,
  similarAdvisors: PropTypes.arrayOf(PropTypes.instanceOf(Advisor)),
  onPrintProfile: PropTypes.func,
  onGetInTouch: PropTypes.func,
  onEditProfile: PropTypes.func,
  onApproveProfile: PropTypes.func,
  onRejectProfile: PropTypes.func,
};
export default AdvisorProfile;
