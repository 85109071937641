import axiosInstance from "./index";
import { baseUrl } from "../config/index";
export async function fetchLogin(data) {
  try {
    const res = await fetch(`${baseUrl}/sign-in`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    
    if(res.status !== 200){
      throw await res.json()
    }
    return await res.json();
  } catch (e) {
    throw e;
  }
}
export function login(data) {
  return axiosInstance({
    method: "post",
    url: "/sign-in",
    data,
  });
}

export function forgotPassword(data) {
  return axiosInstance({
    method: "post",
    url: "/sign-in/forgot",
    data,
  });
}

export function resetPassword(data, token) {
  return axiosInstance({
    method: "post",
    url: `/sign-in/reset?token=${token}`,
    data,
  });
}

export function autoSignIn({ autoSignInHash }) {
  return axiosInstance({
    method: "post",
    url: `/sign-in/auto-sign-in`,
    data: { autoSignInHash },
  });
}
