import React, { useEffect, useState } from "react";
import classes from "./AffinityGroups.module.css";

import {
  createAffinityGroup,
  deleteAffinityGroup,
  listAffinityGroups,
  updateAffinityGroup,
} from "../../../api/affinityGroups";

import { upload } from "../../../utils/upload";
import TextEditor from "../../../components/TextEditor/TextEditor";

const AffinityGroups = () => {
  const [textEditor, setTextEditor] = useState(null);
  const [selectedAffinityGroup, setSelectedAffinityGroup] = useState({});
  const [affinityGroups, setAffinityGroups] = useState([]);
  const [error, setError] = useState("");
  const fetchAffinityGroups = async () => {
    try {
      const response = await listAffinityGroups();
      if (response.data) {
        setAffinityGroups(
          response.data.map((ag) => {
            return {
              ...ag,
              previousObjectUrl: ag.blurb || "",
            };
          })
        );
      }
    } catch (e) {
      setError(e.message);
    }
  };
  useEffect(() => {
    fetchAffinityGroups();
  }, []);
  const onInputFieldChange = (e) => {
    let value = e.target.value;
    setSelectedAffinityGroup({
      ...selectedAffinityGroup,
      [e.target.name]: value,
    });
  };
  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedAffinityGroup({
        ...selectedAffinityGroup,
        [e.target.name]: e.target,
      });
    }
  };
  const deleteAg = async (ag) => {
    try {
      await deleteAffinityGroup(ag.id);
      await fetchAffinityGroups();
    } catch (e) {
      setError(e.message);
    }
  };
  const createAg = async (ag) => {
    try {
      if (ag.blurb?.files?.length > 0) {
        try {
          const result = await upload(
            ag.blurb.files[0],
            ag.previousObjectUrl,
            "image"
          );
          ag.blurb = result.data.link;
        } catch (e) {
          setError(e.message);
        }
      }
      await createAffinityGroup({
        ...ag,
        description: textEditor?.getHTML(),
      });

      await fetchAffinityGroups();
      setError("");
    } catch (e) {
      setError(e.message);
    }
  };
  const updateAg = async (ag) => {
    try {
      if (ag.blurb?.files?.length > 0) {
        try {
          const result = await upload(
            ag.blurb.files[0],
            ag.previousObjectUrl,
            "image"
          );
          ag.blurb = result.data.link;
        } catch (e) {
          setError(e.message);
        }
      }
      await updateAffinityGroup({
        ...ag,
        description: textEditor?.getHTML(),
      });
      await fetchAffinityGroups();
      setError("");
    } catch (e) {
      setError(e.message);
    }
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (selectedAffinityGroup.id) {
      updateAg(selectedAffinityGroup);
    } else {
      createAg(selectedAffinityGroup);
    }
  };
  const affinityGroupsCategories = [
    "Endowments / Foundations / Trusts / Institutional",
    "HNW Individual Impact Investors & Families",
    "Philanthropically Focused",
    "Entrepreneurs",
    "Consultants & Intermediaries",
  ];
  const tableRows = affinityGroups.map((ag) => {
    return (
      <tr
        key={ag.id}
        onClick={(e) => {
          setSelectedAffinityGroup(ag);
          textEditor?.commands?.setContent(ag.description);
        }}
      >
        <td>
          <input
            type="radio"
            name={`selected-affinity-group`}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedAffinityGroup(ag);
                textEditor?.commands?.setContent(ag.description);
              }
            }}
            id={`selected-affinity-group`}
            checked={selectedAffinityGroup.id === ag.id}
          />
        </td>
        <td>{ag.name}</td>
        <td
          style={{
            wordWrap: "break-word",
          }}
        >
          <div className="partners__text" dangerouslySetInnerHTML={{ __html: ag.description }}></div>
        </td>
        <td
          style={{
            wordWrap: "break-word",
          }}
        >
          <a href={ag.website}>{ag.website}</a>
        </td>
        <td>{ag.category}</td>
        <td>
          {ag.blurb && (
            <img width={256} alt="blurb" height={256} src={ag.blurb}></img>
          )}
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div>
        <form onSubmit={onFormSubmit} className={classes.form}>
          <div>
            <label>Name</label>
            <input
              id="affinityGroupName"
              required
              name="name"
              //disabled={selectedAffinityGroup.id ? true : false}
              type="text"
              value={selectedAffinityGroup.name || ""}
              onChange={onInputFieldChange}
            ></input>
          </div>
          <div>
            <label>Description</label>

            <TextEditor
              onCreate={(editor) => setTextEditor(editor)}
            ></TextEditor>
          </div>
          <div>
            <label>Website</label>
            <input
              id="website"
              required
              name="website"
              type="text"
              value={selectedAffinityGroup.website || ""}
              onChange={onInputFieldChange}
            ></input>
          </div>
          <div>
            <label>Category</label>
            <select
              name="category"
              required
              value={selectedAffinityGroup.category}
              onChange={onInputFieldChange}
              id="category"
            >
              <option></option>
              {affinityGroupsCategories.map((category) => {
                return (
                  <option key={category} value={category}>
                    {category}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <label>Blurb</label>
            <input
              id="blurb"
              required={selectedAffinityGroup.id ? false : true}
              name="blurb"
              type="file"
              accept="image/*"
              onChange={onFileChange}
            ></input>
          </div>

          <div>
            <button type="submit">
              {" "}
              {`${selectedAffinityGroup.id ? "Update" : "Create"}`}{" "}
            </button>
            {selectedAffinityGroup.id ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  deleteAg(selectedAffinityGroup);
                }}
              >
                {" "}
                Delete{" "}
              </button>
            ) : null}

            {error ? <label>{error}</label> : null}

            {selectedAffinityGroup.id ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedAffinityGroup({});
                  textEditor?.commands?.setContent("");
                }}
              >
                Cancel
              </button>
            ) : null}
          </div>
        </form>
      </div>
      <div className="uk-overflow-auto">
        <form>
          <table className="uk-table uk-table-striped uk-table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Description</th>
                <th>Website</th>
                <th>Category</th>
                <th>Blurb</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
        </form>
      </div>
    </div>
  );
};

export default AffinityGroups;
