import React, { useContext, useState } from "react";
import "./Resources.css";
import { AuthContext } from "../../Context/AuthContext";
import DownloadPdfModal from "../../components/Modals/DownloadPdfModal";
import { notifyUserDownloadedGuide } from "../../api/email";
import LoginModal from "../../components/Modals/LoginModal";

import ButtonLink from "../../components/_shared/UI/Button/ButtonLink";
const Resources = () => {
  const authContext = useContext(AuthContext);
  const [isDownloadPdfModalOpen, setIsDownloadPdfModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDownloadButtonTitle, setModalDownloadButtonTitle] = useState("");
  const [modalCopyText, setModalCopyText] = useState("");
  const [onDownload, setOnDownload] = useState(() => {});
  const { isLoggedIn } = authContext;

  return (
    <>
      <LoginModal
        show={isLoginModalOpen}
        onClose={() => {
          setIsLoginModalOpen(false);
        }}
      ></LoginModal>
      <DownloadPdfModal
        show={isDownloadPdfModalOpen}
        title={modalTitle}
        copyText={modalCopyText}
        onClose={() => {
          setIsDownloadPdfModalOpen(false);
        }}
        downloadButtonTitle={modalDownloadButtonTitle}
        onDownload={onDownload}
      ></DownloadPdfModal>
      <div className="resources">
        <div className="heading">
          <div className="uk-container">
            <h1>Resources</h1>
            <p>
              We aim to create a knowledge hub for you to get started with
              values investing and ValuesAdvisor.
            </p>
          </div>
        </div>
        <div>
          <div className="uk-container content">
            <div
              className="grid-6-cols"
              id="how-to-use-values-advisors-container"
            >
              <div className="big-item">
                <iframe
                  title={"Valuesadvisors walkthrough"}
                  src="https://player.vimeo.com/video/844706155"
                  width={1920}
                  height={1080}
                  frameBorder={0}
                  allowFullScreen
                  uk-responsive={"true"}
                  uk-video="autoplay: false; automute: false"
                />
              </div>
              <div className="how-to-use-values-text-content">
                <h3>What is ValuesAdvisor?</h3>
                <br></br>
              </div>
            </div>
          </div>
        </div>
        <div className="gray-bg">
          <div id="working-with-advisors" className="uk-container content">
            <h2 className="section-title">Get Expert Help</h2>
            <hr />
            <div className="grid-6-cols">
              <div className="post expert-help">
                <h3>OFFICE HOURS</h3>
                <h5>
                  We offer monthly free office hours to ValuesAdvisor users who
                  want help navigating the platform and getting the most out of
                  our offerings.
                </h5>
                <ButtonLink
                  includeChevron={false}
                  style={{
                    width: "40%",
                    padding: "0",
                    justifyContent: "center",
                    fontSize: "0.8rem",
                    borderRadius: "5px",
                  }}
                  href={`https://portal.valuesadvisor.org/office-hours`}
                >
                  Schedule
                </ButtonLink>
              </div>
              <div className="post expert-help">
                <h3>ONE-ON-ONE</h3>
                <h5>
                  Engage ValuesAdvisor co-founder Kate Simpson, trained life
                  coach, teacher and author who has worked with many investors
                  to help them find the ideal financial advisor.
                </h5>
                <ButtonLink
                  includeChevron={false}
                  style={{
                    width: "40%",
                    padding: "0",
                    justifyContent: "center",
                    fontSize: "0.8rem",
                    borderRadius: "5px",
                  }}
                  href={`https://portal.valuesadvisor.org/coaching`}
                >
                  Schedule
                </ButtonLink>
              </div>

              <div className="post expert-help">
                <h3>CONSULTANT DIRECTORY </h3>
                <h5>
                  As a complement to our Financial Advisor platform, we offer
                  this free directory to help investors looking for Strategic
                  Consultants and Money Coaches.
                </h5>
                <ButtonLink
                  includeChevron={false}
                  style={{
                    width: "40%",
                    padding: "0",
                    justifyContent: "center",
                    fontSize: "0.8rem",
                    borderRadius: "5px",
                  }}
                  href={`https://f.hubspotusercontent20.net/hubfs/7887571/Public%20Facing%20Files/ValuesAdvisor_Strategic%20Consultant%20Directory.pdf`}
                >
                  Download
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
        <div className="orange-bg">
          <div className="uk-container content">
            <h2 className="section-title">FAQs And Guides</h2>
            <hr />

            <div className="grid-6-cols">
              <div className="post">
                <a
                  href="https://f.hubspotusercontent20.net/hubfs/7887571/ValuesAdvisor.Interview.Guide.pdf"
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      e.preventDefault();
                      setModalTitle("How to Interview Financial Advisors.");
                      setModalDownloadButtonTitle(
                        "Download the Advisor Interview Guide"
                      );
                      setModalCopyText(
                        "Our interview guide has some suggestions to dig deeper into an advisor’s services, fees, and investment strategy."
                      );
                      setOnDownload(() => {
                        return async (formData) => {
                          window.open(
                            "https://f.hubspotusercontent20.net/hubfs/7887571/ValuesAdvisor.Interview.Guide.pdf",
                            "_blank"
                          );
                          setIsDownloadPdfModalOpen(false);
                          await notifyUserDownloadedGuide({
                            downloadedFileName: "Advisor Interview Guide",
                            ...formData,
                          });
                        };
                      });
                      setIsDownloadPdfModalOpen(true);
                    }
                  }}
                >
                  <img src="img/find_question.png" alt="Find Question" />
                </a>

                <h3>Find the Right Questions</h3>
                <h5>Advisor Interview Guide</h5>
                <p>
                  Wondering what are the most important questions to ask an
                  advisor? This guide includes our suggested questions to ask
                  potential financial advisors about their impact and financial
                  offerings.
                </p>
              </div>

              <div className="post">
                <a href="/faq">
                  <div className="img--container bg-orange">
                    <img src="img/faq.png" alt="Faq" />
                  </div>
                </a>

                <h3>FAQ</h3>
                <p>Frequently asked questions answered.</p>
              </div>
              <div className="post">
                {isLoggedIn ? (
                  <iframe
                    title={"Valuesadvisors walkthrough"}
                    src="//fast.wistia.com/embed/iframe/ubofy4wkys?version=1"
                    width={1920}
                    height={1080}
                    frameBorder={0}
                    allowFullScreen
                    uk-responsive={"true"}
                    uk-video="autoplay: false; automute: false"
                  />
                ) : (
                  <div className="img--container">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsLoginModalOpen(true);
                      }}
                    >
                      <div className="lock-icon">
                        <h3>Subscribers Only</h3>
                        <img src="img/icons/lock_icon.svg" alt="user"></img>
                      </div>

                      <img
                        className="complete"
                        src="img/video_tutorial.png"
                        alt="Video tutorial"
                      />
                    </a>
                  </div>
                )}

                <h3>In-Depth Video Tutorial of Our Platform</h3>
                <p>
                  Kate Simpson, ValuesAdvisor Co-Founder, gives a detailed
                  walkthrough and tips for using our platform in this video.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="gray-bg">
          <div className="uk-container content">
            <h2 className="section-title">ValuesAdvisor Blog</h2>
            <hr />
            <div className="blog-posts">
              <div className="blog-post">
                <img
                  className="blog-post-img"
                  src="img/break_up.png"
                  alt="Break Up"
                />
                <div className="blog-post-content">
                  <h3>How to Break Up with Your Current Advisor?</h3>
                  <p>
                    It can feel hard - scary even - to think about leaving your
                    current advisor. You may have a long standing relationship
                    with your advisor that makes...
                  </p>
                  <ButtonLink
                    includeChevron={false}
                    style={{
                      maxWidth: "30%",
                      padding: "0",
                      justifyContent: "center",
                      fontSize: "0.8rem",
                      borderRadius: "5px",
                    }}
                    href={`https://portal.valuesadvisor.org/blog/how-to-break-up-with-your-current-advisor`}
                  >
                    Read More
                  </ButtonLink>
                </div>
              </div>
              <div className="blog-post">
                <img
                  className="blog-post-img"
                  src="img/questions.png"
                  alt="Break Up"
                />
                <div className="blog-post-content">
                  <h3>
                    {" "}
                    5 Questions to Ask Your Advisor About Values Aligned
                    Investing
                  </h3>
                  <p>
                    Why use a financial advisor in the first place? Some life
                    factors which may lead you to hiring an advisor, A major
                    life event such as marriage or divorce, having kids, or new
                    investable assets...
                  </p>
                  <ButtonLink
                    includeChevron={false}
                    style={{
                      maxWidth: "30%",
                      padding: "0",
                      justifyContent: "center",
                      fontSize: "0.8rem",
                      borderRadius: "5px",
                    }}
                    href={`/blog/5-questions-to-ask-your-advisor-about-values-aligned-investing`}
                  >
                    Read More
                  </ButtonLink>
                </div>
              </div>
              <div className="blog-post">
                <img
                  className="blog-post-img"
                  src="img/success_story.png"
                  alt="Break Up"
                />
                <div className="blog-post-content">
                  <h3>A ValuesAdvisor Success Story</h3>
                  <h6>By Ellen, ValuesAdvisor user</h6>
                  <p>
                    About ten years ago, when I became an inheritor, all I knew
                    was that I wanted to get the money out of Merrill Lynch and
                    into someplace where I could be sure it would do no harm...{" "}
                  </p>
                  <ButtonLink
                    includeChevron={false}
                    style={{
                      maxWidth: "30%",
                      padding: "0",
                      justifyContent: "center",
                      fontSize: "0.8rem",
                      borderRadius: "5px",
                    }}
                    href={`/blog/a-valuesadvisor-success-story`}
                  >
                    Read More
                  </ButtonLink>
                </div>
              </div>
              <div className="blog-post">
                <img
                  className="blog-post-img"
                  src="img/genesis_story.png"
                  alt="Break Up"
                />
                <div className="blog-post-content">
                  <h3>Genesis Story</h3>
                  <h6>By Lisa Renstrom, ValuesAdvisor Founder</h6>
                  <p>
                    I created ValuesAdvisor because I want to make it easier to
                    find a values-aligned financial advisor, and remove any
                    barriers (or excuses) for HNW to align their values with...
                  </p>
                  <ButtonLink
                    includeChevron={false}
                    style={{
                      maxWidth: "30%",
                      padding: "0",
                      justifyContent: "center",
                      fontSize: "0.8rem",
                      borderRadius: "5px",
                    }}
                    href="/blog/our-founders-story"
                  >
                    Read More
                  </ButtonLink>
                </div>
              </div>
              <div className="blog-post">
                <img
                  className="blog-post-img"
                  src="img/find-advisor-with-confidence.webp"
                  alt="Break Up"
                />
                <div className="blog-post-content">
                  <h3>How to Find a Values-Aligned Advisor With Confidence</h3>
                  <p>
                    Money can be a difficult topic for many people, no matter
                    what their financial needs are. Especially for those looking
                    to align their money with their values, it is easy to feel
                    uncertain about...
                  </p>
                  <ButtonLink
                    includeChevron={false}
                    style={{
                      maxWidth: "30%",
                      padding: "0",
                      justifyContent: "center",
                      fontSize: "0.8rem",
                      borderRadius: "5px",
                    }}
                    href={`/blog/find-advisor-with-confidence`}
                  >
                    Read More
                  </ButtonLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="uk-container content">
            <h2 className="section-title">Resources from our Partner Groups</h2>
            <hr />

            <div className="grid-6-cols">
              <div className="post">
                <a href="/blog/finding-the-right-advisor-to-help-align-your-investments-with-your-values">
                  <div className="img--container bg-gray">
                    <img
                      src="img/family_philanthropy.png"
                      alt="Family Philanthropy"
                    />
                  </div>
                </a>

                <h3>
                  Finding the Right Advisor to Help Align Your Investments With
                  Your Values
                </h3>
                <p>Kate Simpson, ValuesAdvisor</p>
              </div>
              <div className="post">
                <a href="https://uploads-ssl.webflow.com/5e70effbd74618efba99a8ed/63459f69eb48751e83fbecf2_Ten_Ingredients_for_Impact_Investing.pdf">
                  <div className="img--container">
                    <img className="complete" src="img/eggs.png" alt="Eggs" />
                  </div>
                </a>

                <h3>Ten Ingredients for Impact Investing</h3>
                <p>
                  The Center for Sustainable Finance and Private Wealth at the
                  University of Zurich
                </p>
              </div>
              <div className="post">
                <a href="https://toniic.com/impact-investing/">
                  <div className="img--container bg-blue">
                    <img
                      className="logo-small"
                      src="img/toniic.png"
                      alt="Toniic"
                    />
                  </div>
                </a>

                <h3>Impact Investing – the Toniic Approach</h3>
                <p>Adam Bendell, Toniic CEO</p>
              </div>
              <div className="post">
                <a href="https://investforbetter.org/resources/values/">
                  <div className="img--container bg-orange">
                    <img
                      className="logo-small"
                      src="img/invest_better.png"
                      alt="Invest Better"
                    />
                  </div>
                </a>

                <h3>Invest Your Values</h3>
                <p>Invest for Better</p>
              </div>
              <div className="post">
                <a href="https://thegiin.org/characteristics/">
                  <div className="img--container bg-yellow">
                    <img
                      className="logo-small"
                      src="img/global_impact.png"
                      alt="Global Impact"
                    />
                  </div>
                </a>

                <h3>Core Characteristics of Impact Investing</h3>
                <p>GIIN (The Global Impact Investing Network)</p>
              </div>
            </div>
          </div>
        </div>
        <div className="gray-bg">
          <div id="working-with-advisors" className="uk-container content">
            <div className="find-community-container">
              <div className="post expert-help find-community">
                <h2>Find Community</h2>
                <h5>
                  Discover organizations and peers who are in the process of
                  aligning their investments with their values.
                </h5>
                <ButtonLink
                  includeChevron={false}
                  style={{
                    width: "40%",
                    padding: "0",
                    justifyContent: "center",
                    fontSize: "0.8rem",
                    borderRadius: "5px",
                  }}
                  href={`/about`}
                >
                  Learn More
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Resources
