import React from "react";

import "./PaymentComplete.css";

export const JoinPaymentComplete = () => {
 

  return (
    <div className="complete---container">
      <div className="description">
        <h2 className="confirmation-title">Confirmation</h2>
        <h3>
          <b>Thank you for completing signup!</b>
        </h3>
        <p>
          On behalf of ValuesAdvisor team, welcome aboard! Your payment receipt
          and account confirmation has been sent to your email. Please don’t
          hesitate to contact us a{" "}
          <a className="info-mail-link" href="mailto:info@valuesadvisor.org">
            <b>info@valuesadvisor.org</b>
          </a>
          , should you have any question.
          <br />
          <br />
          If not, let’s get started!
        </p>
        <a href="/login" className="get-started-button homelink">
          Get Started
          <svg
            className="homelink__chevron"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="19.521"
            viewBox="0 0 11 19.521"
          >
            <g id="Expand" transform="translate(0 19.521) rotate(-90)">
              <path
                id="Path_434"
                data-name="Path 434"
                d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                transform="translate(-178.807 -14.7)"
                fill="#ff6b00"
              />
            </g>
          </svg>
        </a>
        <p className="redirect-text">
          Redirected to Find an Advisor page in 5 seconds…
        </p>
      </div>
    </div>
  );
};
