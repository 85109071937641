import ReactGA from "react-ga4";
import { useState } from "react";
import { contactValuesAdvisor,contactAdvisor } from "../api/contactUs"

export const SEND_TO = {
  VALUES_ADVISOR_STAFF: 1,
  ADVISOR: 2,
};
export const useContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const clearForm = () => {
    setName("");
    setSubject("");
    setEmail("");
    setMessage("");
  };
  const sendToValuesStaff = async (formData) => {
    try {
      setLoading(true);
      const response = await contactValuesAdvisor(formData);
      if (response.data.success) {
        clearForm();
        setSuccessMessage("We will get in touch with you, shortly");
        setLoading(false);
      }
    } catch (error) {
      console.error(error.response.data.error);
      setSuccessMessage(error.response.data.error);
      setLoading(false);
    }
  };
  const sendToAdvisor = async (formData) => {
    try {
      setLoading(true);
      const response = await contactAdvisor(formData);
      if (response.data.success) {
        clearForm();
        setLoading(false);
        ReactGA.event({
          category: `advisor profile`,
          action: "contacted - quick ask",
          label: formData.advisorName,
          value: 1,
        });
      }
    } catch (error) {
      console.error(error.response.data.error);
      setSuccessMessage(error.response.data.error);
      setLoading(false);
    }
  };
  const submitForm = async (to, formData) => {
    switch (to) {
      case SEND_TO.VALUES_ADVISOR_STAFF:
        await sendToValuesStaff(formData);
        break;
      case SEND_TO.ADVISOR:
          await sendToAdvisor(formData);
          break;
      default:
      await sendToValuesStaff(formData);
    }
  };
  return {
    name,
    setName,
    email,
    setEmail,
    subject,
    setSubject,
    message,
    setMessage,
    loading,
    successMessage,
    submitForm,
  };
};
