import React, { useEffect, useState, useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import {
  fetchAllType,
  getAdvisorProfile,
  getSimilarAdvisors,
} from "../../api/advisor";

import { getUserToken, acceptAdvisor, rejectAdvisor } from "../../api/admin";
import { contactAdvisorFollowUp } from "../../api/email";
import AdvisorProfile from "./AdvisorProfile";
import { Advisor } from "../../models/advisor";
//import { useAuth } from "../../hooks/useAuth";
import { useContactForm, SEND_TO } from "../../hooks/useContactForm";
import { ADVISOR_STATUS } from "../../constants/constants";
import { AuthContext } from "../../Context/AuthContext";
import { Redirect } from "react-router-dom";

const Container = ({ match, history }) => {
  const authContext = useContext(AuthContext);
  const {
    name: sayHiName,
    setName: setSayHiName,
    email: sayHiEmail,
    setEmail: setSayHiEmail,
    message: sayHiMessage,
    setMessage: setSayHiMessage,
    submitForm,
  } = useContactForm();

  const advisorProfileId = +match.params.id;
 
  const { isAdmin, isAdvisor, advisorId } = authContext;
  const [advisorProfile, setAdvisorProfile] = useState(null);
  const [similarAdvisors, setSimilarAdvisors] = useState([]);
  const [types, setTypes] = useState(null);

  const isValidId = !isNaN(advisorProfileId) && advisorProfileId > 0;
  const getAdvisorProfileData = useCallback(async () => {
    if (isValidId) {
    try {
      const getAdvisorProfileResponse = await getAdvisorProfile(
        advisorProfileId
      );
      setAdvisorProfile(new Advisor(getAdvisorProfileResponse.data));

      const fetchAllTypeResponse = await fetchAllType();
      setTypes(fetchAllTypeResponse.data);
      const getSimilarAdvisorsResponse = await getSimilarAdvisors(
        advisorProfileId
      );
      setSimilarAdvisors(getSimilarAdvisorsResponse.data.map(data => new Advisor(data)));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // The advisor was not found
        window.location = "/search";
        return;
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  }
  }, [advisorProfileId,isValidId]);
 
  useEffect(() => {
    //call API and fetch advisor
    getAdvisorProfileData();
  }, [getAdvisorProfileData]);
  if(!isValidId) {
    return <Redirect to="/search" /> 
  }
  if (!advisorProfile || !types) {
    return null;
  }
  
  const onSendHiInfo = async () => {
    await submitForm(SEND_TO.ADVISOR, {
      name: sayHiName,
      email: sayHiEmail,
      message: sayHiMessage,
      advisorId: advisorProfileId,
      advisorName: advisorProfile.name,
    });
  };
  const {
    name,
    suggestedByMembers,
    teamInfo,
    profilePic,
    addresses,
    website,
    firm,
    socialLinks,
    materials,
    user,
    title,
    valuesAlignedInvestingInfo,
    richMediaLinks,
    currentADV,
  } = advisorProfile;

  const impactThemesExpertise = teamInfo.impactThemes
    .map((it) => {
      const type = types["impactThemesType"].find(
        (t) => t.id === it.impactThemeId
      );
      return type;
    })
    .filter((o) => o);

  const suggestedByMemberTypes = suggestedByMembers
    .map((it) => {
      const type = types["suggestedByMemberType"].find(
        (t) => t.id === it.suggestedByMemberId
      );
      return type;
    })
    .filter((o) => o);

  const socialLinkTypes = socialLinks
    .map((it) => {
      const type = types["socialLinkType"].find(
        (t) => t.id === it.socialLinkId
      );
      return { link: it.link, ...type };
    })
    .filter((o) => o);

  const materialTypes = materials
    .map((it) => {
      const type = types["materialType"].find((t) => t.id === it.materialId);
      return { link: it.link, ...type };
    })
    .filter((o) => o);
  const unsdgTypes = teamInfo.unSdgs
    .map((it) => {
      const type = types["unSdgType"].find((t) => t.id === it.unSdgId);
      return type;
    })
    .filter((o) => o);
  const tabProps = {
    types,
    valuesAlignedInvestingInfo,
  };
  const downloadLinksProps = {
    materialTypes,
    richMediaLinks,
    currentADV,
  };
  const introductionProps = {
    profilePic,
    name,
    suggestedByMemberTypes,
    user,
    impactThemesExpertise,
    title,
  };
  const isProfileOwner = +advisorId === +advisorProfileId;
  const canEdit = isProfileOwner || isAdmin;
  const onEditProfile = canEdit
    ? async () => {
        try {
          const response = await getUserToken(advisorProfileId);
          if (response) {
            history.push(`/profile-edit?token=${response.data.token}`);
          }
        } catch (error) {
          throw error;
        }
      }
    : null;
  const onApproveProfile =
    isAdmin && user.status.name === ADVISOR_STATUS.PENDING
      ? async () => {
          await acceptAdvisor(advisorProfileId);
          const newProfile = await getAdvisorProfile(advisorProfileId).then(
            ({ data }) => {
              return data;
            }
          );
          setAdvisorProfile(new Advisor(newProfile));
        }
      : null;

  const onRejectProfile =
    isAdmin && user.status.name === ADVISOR_STATUS.PENDING
      ? async () => {
          await rejectAdvisor(advisorProfileId);
          const newProfile = await getAdvisorProfile(advisorProfileId).then(
            ({ data }) => {
              return data;
            }
          );
          setAdvisorProfile(new Advisor(newProfile));
        }
      : null;
  const onGetInTouch = isProfileOwner
    ? null
    : (e) => {
        ReactGA.event({
          category: `advisor profile`,
          action: "contacted",
          label: advisorProfile.name,
          value: 1,
        });
        contactAdvisorFollowUp();
      };

  return (
    <AdvisorProfile
      teamInfo={teamInfo}
      firm={firm}
      addresses={addresses}
      downloadLinksProps={downloadLinksProps}
      introductionProps={introductionProps}
      similarAdvisors={similarAdvisors}
      socialLinkTypes={socialLinkTypes}
      tabProps={tabProps}
      unsdgTypes={unsdgTypes}
      website={website}
      onPrintProfile={() => {
        window.print();
      }}
      onGetInTouch={onGetInTouch}
      onEditProfile={onEditProfile}
      onApproveProfile={onApproveProfile}
      onRejectProfile={onRejectProfile}
      showSimilarAdvisors={isAdmin || !isAdvisor}
      setSayHiName={setSayHiName}
      setSayHiEmail={setSayHiEmail}
      setSayHiMessage={setSayHiMessage}
      onSendHiInfo={onSendHiInfo}
    />
  );
};

export default withRouter(Container);
